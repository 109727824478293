<template>
  <div style="min-height: 100vh; flex: 1">
    <topNavBar :leftIcon="true" />

    <div
      id="searchPage"
      class="base-input v-w-full v-p-10 v-pr-10 v-rounded-10"
    >
      <van-search
        v-model="getRealValue"
        :placeholder="$t('search-search_product')"
        :left-icon="''"
        shape="round"
        style="height: 11vw; padding: 0 3vw; font-size: 3.73333vw"
        @input="onSearchInput"
      />
      <div class="k-text v-text-12 v-mt-10 v-pl-20">
        {{ $t("search-search_result") }}
      </div>

      <div class="result-list">
        <div v-for="item in filteredProductList" :key="item">
          <div
            class="v-v-rounded-10 v-px-14 v-py-8 v-m-10 flex items-center text-center justify-between"
            @click="$SkipPage(item)"
          >
            <div>
              <span class="k-text">{{ item.symbol }}</span>
              <span class="v-pl-20 text-[#dcdcdc] v-text-12">{{
                item.close
              }}</span>
            </div>
            <van-icon name="arrow" style="font-size: 1.55rem" />
          </div>
        </div>
      </div>
      <div v-if="filteredProductList.length == 0">
        <noRecord />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import topNavBar from "@/pages/components/common/topNavBar.vue";
import noRecord from "@/pages/components/common/noRecord.vue";
import { useStore } from "vuex";

const { appContext } = getCurrentInstance();
const { t } = useI18n();
const router = useRouter();
const getRealValue = ref("");
const productList = appContext.config.globalProperties.$lineData;
const filteredProductList = ref([]);
const store = useStore();

onMounted(() => {
  setupWebSocket();
});

// 避免中文输入法和js enter回车提交冲突
function debounce(func, delay) {
  let timer;
  return function (...args) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

// 搜索
const onSearchInput = debounce(async () => {
  const arr = await store.dispatch("GetDataByType", {
    data: { ticker: getRealValue.value },
  });
  filteredProductList.value = arr || [];
}, 100);

const socket = ref();
function setupWebSocket() {
  socket.value = new WebSocket("wss://api.hzwh888.vip:2348"); //new WebSocket('wss://api.jiekourapi.com:2348'); // 使用本地开发服务器的 WebSocket URL
  socket.value.onmessage = (event) => {
    const data = JSON.parse(event.data);
    updateData(data);
  };

  socket.value.onopen = () => {
    console.log("WebSocket 连接已建立");
  };
}

function updateData(newData) {
  // 将 newData 转换为查找对象（哈希表），以 symbol 为键
  const newDataMap = newData.reduce((acc, dataItem) => {
    acc[dataItem.symbol] = dataItem;
    return acc;
  }, {});

  // 更新 lineData 数组
  filteredProductList.value = filteredProductList.value.map((item) => {
    // 查找对应的更新数据
    const updatedItem = newDataMap[item.symbol];
    // 如果存在更新的数据，合并原数据和新数据；否则，保持原数据不变
    return updatedItem ? { ...item, ...updatedItem } : item;
  });
}

onBeforeUnmount(() => {
  socket.value.close();
});
</script>

<style scoped></style>
