export default {
  "app-title": "FBS",
  "app-title-2": "売れない",
  "app-title-language": "言語",
  "title-detail": "詳細",
  "title-welcome_to_use": "ご利用ありがとうございます",

  //tabBar
  "tabbar-market": "マーケット",
  "tabbar-order": "注文",
  "tabbar-news": "ニュース",
  "tabbar-mine": "マイページ",
  "public-night_mode": "ナイトモード",
  "public-noRecord": "データがありません",
  "public-text-cancel": "キャンセル",
  "public-text-confirm": "確認",
  "public-text-balance": "残高",
  "public-text-date": "時間",
  "public-text-completed": "完了",
  "public-text-invalid": "無効",
  "public-text-unchecked": "未確認",
  "public-text-remarks": "備考",
  "public-text-loading": "読み込み中",
  "public-text-loose-to-refresh": "引き下げてリフレッシュ...",
  "public-text-buy": "購入",
  "public-text-sell": "売却",
  "public-text-close": "取引終了",
  "public-text-lots_1": "ロット",
  "public-text-lots": "ロット数",
  "public-text-available": "利用可能",
  "public-text-freeze": "凍結",
  "public-text-scroll_down_refresh": "下にスワイプしてリフレッシュ...",
  "public-btn-submit": "送信",
  "public-btn-add": "追加",
  "public-btn-copy": "コピー",
  "public-btn-delete": "削除",
  "public-btn-copyLink": "招待リンクをコピー",
  "public-btn-send": "送信",
  "public-btn-download": "ダウンロード",
  "public-btn-resetPassword": "パスワードをリセット",
  "public-btn-confirmation": "確認",
  "public-btn-accept": "受け入れる",
  "public-enterEmail": "メールアドレスを入力してください",
  "public-password": "パスワード",
  "public-retypePassword": "パスワードを再入力",
  "public-inviteCode": "招待コード",
  "public-verifyCode": "認証コード",
  "login-newUser": "新規ユーザー?",
  "login-joinNow": "今すぐ参加",
  "login-forgotPassword": "パスワードを忘れた",
  "login-btn-login": "ログイン",
  "login-btn-demoAcc":
    "アカウントを開設せずに、すぐにデモアカウントを作成して取引できます!",
  "login-enterEmail": "メールアドレスを入力してください",
  "login-enterPassword": "パスワードを入力してください",
  "regsiter-btn-register": "登録",
  "regsiter-enterInviteCode": "招待コードを入力してください",
  "regsiter-enterVerityCode": "認証コードを入力してください",
  "logout-title": "ログアウト",
  "systemHint-reset_successful": "リセット成功",
  "systemHint-incorrectVerityCode": "認証コードが間違っています",
  "systemHint-passwordsDoNotMatch": "パスワードが一致しません",
  "systemHint-password_error": "パスワードが間違っています",
  "systemHint-login_connot_find_user": "ユーザーが見つかりません",
  "systemHint-copySuccess": "コピー成功",
  "systemHint-plsCompleted": "すべての項目を入力してください",
  "systemHint-pwdInconsistent": "パスワードが一致しません",
  "systemHint-incorrectVerifyCode": "認証コードが間違っています",
  "systemHint-changeSuccess": "変更成功",
  "systemHint-plsSubmit": "すべての情報を送信してください",
  "systemHint-binb-bank-not-open-yet": "この通貨の出金はまだ開放されていません",
  "systemHint-plsFillIn": "すべての項目を入力してください",
  "systemHint-requestSuccessful": "リクエスト成功",
  "systemHint-operation_successful": "操作成功",
  "systemHint-set_successful": "設定成功",
  "systemHint-missing_params-or-val-error":
    "パラメーターが不足しているか、値のエラー",
  "systemHint-limit_trade_price_must_greater":
    "制限価格の取引価格は0より大きくする必要があります",
  "systemHint-buy-stop_loss_price_higher_than_opening_price":
    "購入（ロング）ストップロス価格は開場価格を超えてはいけません",
  "systemHint-buy-stop_profit_price_higher_than_opening_price":
    "購入（ロング）ストップ利益価格は開場価格を超えてはいけません",
  "systemHint-sell-stop_loss_price_lower_than_opening_price":
    "売却（ショート）ストップロス価格は開場価格を下回ってはいけません",
  "systemHint-sell-stop_profit_price_lower_than_opening_price":
    "売却（ショート）ストップ利益価格は開場価格を下回ってはいけません",
  "systemHint-code_send_succesful": "認証コードが送信されました",
  "systemHint-pls_register_login_real_account":
    "本物のアカウントに登録またはログインしてください",
  "systemHint-successful_exit": "ログアウト成功",
  "popupHint-set_up_collection_account": "先に受取口座を設定してください",
  "popupHint-confirm_to_delete": "削除してもよろしいですか？",
  "popupHint-whether_return_it": "返却しますか？",
  "mine-availableAssets": "利用可能な資産",
  "mine-account_details": "アカウント詳細",
  "mine-deposit": "入金",
  "mine-withdraw": "出金",
  "mine-billDetails": "請求書詳細",
  "mine-demo_return_simulated_account": "デモアカウントをリセット",
  "mine-wallet": "ウォレット",
  "mine-verifyName": "本人確認",
  "mine-inviteFriend": "友達を招待",
  "mine-changePassword": "パスワード変更",
  "mine-onlineSerive": "カスタマーサポート",
  "mine-complaintEmail": "クレームメール",
  "mine-announcement": "お知らせ",
  "mine-establishment": "設定",
  "mine-other": "その他",
  "mine-change_theme": "テーマ変更",
  "dialog-title": "お知らせ",
  "dialog-logout-conetnt": "ログアウトしてもよろしいですか？",
  "announce-text":
    "政策により、現在、北朝鮮、イスラエル、中国、バヌアツ、キューバへのサービスを提供しておりません。",
  "announce-text-demo": "（デモはテストのみで使用されます）",
  "verify-text-upload":
    "パスポート/身分証明書の表面と裏面をアップロードしてください",
  "verify-front": "表面",
  "verify-front-desc": "パスポート/身分証明書の表面をアップロード",
  "verify-back": "裏面",
  "verify-back-desc": "パスポート/身分証明書の裏面をアップロード",
  "verify-text-info": "個人情報を入力してください",
  "verify-name": "名前",
  "verify-enterName": "名前を入力してください",
  "verify-passportId": "パスポート/身分証明書番号",
  "verify-enterPassportId": "パスポート/身分証明書番号を入力してください",
  "verify-infoAuth": "審査中",
  "wallet-bind-bank": "銀行カードを連携",
  "wallet-bind-currency-address": "暗号通貨アドレスを連携",
  "bind-bank-name": "銀行名",
  "bind-bank-address": "銀行の住所",
  "bind-bank-swift": "SWIFT",
  "bind-bank-payee": "受取人",
  "bind-bank-payee-account": "受取人アカウント",
  "bind-currency": "通貨",
  "bind-wallet-address": "ウォレットアドレス",
  "deposit-digitai-currency": "暗号通貨",
  "deposit-bank-card": "銀行カード",
  "deposit-select_network": "ネットワーク選択",
  "deposit-coin-number": "入金数量",
  "deposit-coin-placeholder": "入金するコインの数量を入力してください",
  "doposit-upload-cert": "証明書をアップロード",
  "deposit-record": "入金記録",
  "deposit-recharge_amount": "入金額",
  "deposit-recharge-placeholder": "入金額を入力してください",
  "deposit-recharge_type": "タイプ",
  "deposit-recharge_unit": "単位",
  "deposit-recharge_status": "ステータス",
  "deposit-receipt_currency": "受取通貨",
  "withdraw-coin_address": "出金アドレス",
  "withdraw-coin_amount": "数量",
  "withdraw-handling_fee": "手数料",
  "withdraw-expected": "予想される入金額",
  "withdraw-btn-submit": "出金を提出",
  "withdraw-coin_withdrawal_record": "出金記録",
  "withdraw-num_of_receipt": "受取数量",
  "withdraw-select_addess": "出金アドレスを選択してください",
  "order-position_holding": "ポジション保有",
  "order-pending_orders": "保留注文",
  "order-history": "履歴",
  "order-profit_loss": "総利益/損失",
  "order-current_margin": "現在のマージン",
  "order-risk_rate": "リスク率",
  "order-order_id": "注文ID",
  "order-total_profit_loss": "総利益/損失",
  "order-order_details": "注文詳細",
  "order-margin": "マージン",
  "order-take_profit": "利確",
  "order-set_loss": "損切り",
  "order-set_TPSL": "TP/SL変更",
  "order-close_position": "ポジションクローズ",
  "order-cancel_order": "注文キャンセル",
  "order-select_period": "期間選択",
  "order-btn_rise": "上昇",
  "order-btn_fall": "下降",
  "order-estimated_return": "推定リターン",
  "order-purchase_amount": "購入額",
  "search-search_product": "商品検索",
  "search-search_result": "検索結果",
  "trade-market_price": "市場価格",
  "trade-price": "価格",
  "trade-quick_trade": "即時契約",
  "trade-please_enter_price": "価格を入力してください",
  "trade-each_lots": "ロットごと",
  "trade-estimated_handling_fee": "予想手数料",
  "trade-estimated_margin": "予想マージン",
  "trade-lots_text": "購入数量（ステップ：0.001）",
  "trade-order_page": "注文ページ",
  "trade-your_order_has_been_confirmed": "注文が確認されました",
  "trade-multiplier": "倍率",
  "accHistory-financial_records": "財務記録",
  "accHistory-records": "記録",

  Res_Msg1: "メールアドレスを入力してください",
  Res_Msg2: "メールアドレスの形式が無効です",
  Res_Msg3: "パスワードを入力してください",
  Res_Msg4: "パスワードと確認用パスワードが一致しません",
  Res_Msg5: "パスワードは6文字以上である必要があります",
  Res_Msg6: "認証コードが無効です",
  Res_Msg7: "認証コードを入力してください",
  Res_su: "登録成功",
  Login_su: "ログイン成功",
  Loading: "これ以上データはありません",
  SDealCont_TopName: "デリバリー",
  SDealCont_L1: "購入価格",
  SDealCont_L2: "現在の価格",
  SDealCont_L3: "方向",
  SDealCont_L4: "数量",
  SDealCont_L5: "デリバリー時間",
  SDealCont_btn: "閉じる",
  SDealCont_L6: "ロング買い",
  SDealCont_L7: "ショート売り",
  SDealCont_L8: "購入",
  SDealCont_L9: "売却",
  "verify-verified": "認証成功",
  "verify-notVerified": "認証失敗",
  "加载中...": "読み込み中...",
};
