export default {
    'app-title': 'FBS',
    'app-title-2': 'Non Vendibile',
    'app-title-language': 'Lingua',
    'title-detail': 'Dettaglio',
    'title-welcome_to_use': 'Benvenuto',

    //tabBar
    'tabbar-market': 'Mercato',
    'tabbar-order': 'Ordini',
    'tabbar-news': 'Notizie',
    'tabbar-mine': 'Io',
    'public-night_mode': 'Modalità Notte',
    'public-noRecord': 'Nessun dato ancora',
    'public-text-cancel': 'Annulla',
    'public-text-confirm': 'Conferma',
    'public-text-balance': 'Saldo',
    'public-text-date': 'Data',
    'public-text-completed': 'Completato',
    'public-text-invalid': 'Non Valido',
    'public-text-unchecked': 'Non Controllato',
    'public-text-remarks': 'Osservazioni',
    'public-text-loading': 'Caricamento',
    'public-text-loose-to-refresh': 'Rilascia per aggiornare...',
    'public-text-buy': 'Compra',
    'public-text-sell': 'Vendi',
    'public-text-close': 'Chiusa',
    'public-text-lots_1': 'Lotti',
    'public-text-lots': 'Lotti',
    'public-text-available': 'Disponibile',
    'public-text-freeze': 'Congela',
    'public-text-scroll_down_refresh': 'Scorri verso il basso per aggiornare...',
    'public-btn-submit': 'Invia',
    'public-btn-add': 'Aggiungi',
    'public-btn-copy': 'Copia',
    'public-btn-delete': 'Elimina',
    'public-btn-copyLink': 'Copia Link di Invito',
    'public-btn-send': 'Invia',
    'public-btn-download': 'SCARICA',
    'public-btn-resetPassword': 'Reimposta Password',
    'public-btn-confirmation': 'Conferma',
    'public-btn-accept': 'Accetta',
    'public-enterEmail': 'Inserisci la tua Email',
    'public-password': 'Password',
    'public-retypePassword': 'Ridigita la password',
    'public-inviteCode': 'Codice di Invito',
    'public-verifyCode': 'Codice di Verifica',
    'login-newUser': 'Nuovo Utente?',
    'login-joinNow': 'Iscriviti Ora',
    'login-forgotPassword': 'Password Dimenticata',
    'login-btn-login': 'Accedi',
    'login-btn-demoAcc': 'Non è necessario aprire un account, crea un account demo per fare trading ora!',
    'login-enterEmail': 'Per favore inserisci il tuo indirizzo email',
    'login-enterPassword': 'Per favore inserisci la tua password',
    'regsiter-btn-register': 'Registrati',
    'regsiter-enterInviteCode': 'Per favore inserisci il codice di invito',
    'regsiter-enterVerityCode': 'Per favore inserisci il codice di verifica',
    'logout-title': 'Esci',
    'systemHint-reset_successful': 'Reset riuscito',
    'systemHint-incorrectVerityCode': 'Codice di Verifica Errato',
    'systemHint-passwordsDoNotMatch': 'Le password non corrispondono',
    'systemHint-password_error': 'Errore nella password',
    'systemHint-login_connot_find_user': 'Impossibile trovare l\'utente',
    'systemHint-copySuccess': 'Copia riuscita',
    'systemHint-plsCompleted': 'Per favore completa',
    'systemHint-pwdInconsistent': 'Password Incoerente',
    'systemHint-incorrectVerifyCode': 'Codice di Verifica Errato',
    'systemHint-changeSuccess': 'Modifica Riuscita',
    'systemHint-plsSubmit': 'Per favore invia informazioni complete',
    'systemHint-binb-bank-not-open-yet': 'Il prelievo di questa valuta non è ancora aperto',
    'systemHint-plsFillIn': 'Per favore compila completamente',
    'systemHint-requestSuccessful': 'Richiesta Riuscita',
    'systemHint-operation_successful': 'Operazione Riuscita',
    'systemHint-set_successful': 'Impostazioni Riuscite',
    'systemHint-missing_params-or-val-error': 'Parametri mancanti o errore nel valore passato',
    'systemHint-limit_trade_price_must_greater': 'Il prezzo di scambio limitato deve essere maggiore di 0',
    'systemHint-buy-stop_loss_price_higher_than_opening_price': 'Il prezzo di stop loss per acquisto (lungo) non può essere superiore al prezzo di apertura',
    'systemHint-buy-stop_profit_price_higher_than_opening_price': 'Il prezzo di stop profit per acquisto (lungo) non può essere superiore al prezzo di apertura',
    'systemHint-sell-stop_loss_price_lower_than_opening_price': 'Il prezzo di stop loss per vendita (corta) non può essere inferiore al prezzo di apertura',
    'systemHint-sell-stop_profit_price_lower_than_opening_price': 'Il prezzo di stop profit per vendita (corta) non può essere inferiore al prezzo di apertura',
    'systemHint-code_send_succesful': 'Codice di verifica inviato con successo',
    'systemHint-pls_register_login_real_account': 'Per favore registrati o accedi a un vero account',
    'systemHint-successful_exit': 'Uscita Riuscita',
    'popupHint-set_up_collection_account': 'Per favore imposta prima un account di raccolta',
    'popupHint-confirm_to_delete': 'Sei sicuro di voler eliminare?',
    'popupHint-whether_return_it': 'Se deve essere restituito',
    'mine-availableAssets': 'Attivi Disponibili',
    'mine-account_details': 'Dettagli Account',
    'mine-deposit': 'Deposita',
    'mine-withdraw': 'Preleva',
    'mine-billDetails': 'Dettagli Fattura',
    'mine-demo_return_simulated_account': 'Torna all\'account simulato',
    'mine-wallet': 'Portafoglio',
    'mine-verifyName': 'Verifica Nome',
    'mine-inviteFriend': 'Invita Amici',
    'mine-changePassword': 'Cambia Password',
    'mine-onlineSerive': 'Servizio Online',
    'mine-complaintEmail': 'Email Reclami',
    'mine-announcement': 'Annuncio',
    'mine-establishment': 'Fondazione',
    'mine-other': 'Altro',
    'mine-change_theme': 'Cambia Tema',
    'dialog-title': 'Avviso',
    'dialog-logout-conetnt': 'Sei sicuro di voler uscire?',
    'announce-text': 'Per motivi di politica, i servizi non sono forniti a Corea del Nord, Israele, Cina, Vanuatu e Cuba.',
    'announce-text-demo': '(FBS Solo per test)',
    'verify-text-upload': 'Per favore carica il fronte e il retro del tuo passaporto/ID',
    'verify-front': 'Fronte',
    'verify-front-desc': 'Carica il fronte del tuo passaporto/certificato',
    'verify-back': 'Retro',
    'verify-back-desc': 'Carica il retro del tuo passaporto/certificato',
    'verify-text-info': 'Per favore completa le tue informazioni personali',
    'verify-name': 'Nome',
    'verify-enterName': 'Per favore inserisci il tuo nome',
    'verify-passportId': 'Numero Passaporto/ID',
    'verify-enterPassportId': 'Per favore inserisci il numero del passaporto/ID',
    'verify-infoAuth': 'Verifica',
    'wallet-bind-bank': 'Collega Carta Bancaria',
    'wallet-bind-currency-address': 'Collega Indirizzo di Valuta Digitale',
    'bind-bank-name': 'Nome Banca',
    'bind-bank-address': 'Indirizzo Banca',
    'bind-bank-swift': 'SWIFT',
    'bind-bank-payee': 'Beneficiario',
    'bind-bank-payee-account': 'Conto Beneficiario',
    'bind-currency': 'Valuta',
    'bind-wallet-address': 'Indirizzo Portafoglio',
    'deposit-digitai-currency': 'Valuta Digitale',
    'deposit-bank-card': 'Carta Bancaria',
    'deposit-select_network': 'Seleziona Rete',
    'deposit-coin-number': 'Numero di Monete',
    'deposit-coin-placeholder': 'Per favore inserisci il numero di monete da depositare',
    'doposit-upload-cert': 'Carica Certificato',
    'deposit-record': 'Registro Deposito',
    'deposit-recharge_amount': 'Importo Ricarica',
    'deposit-recharge-placeholder': 'Per favore inserisci l\'importo della ricarica',
    'deposit-recharge_type': 'Tipo',
    'deposit-recharge_unit': 'Unità',
    'deposit-recharge_status': 'Stato',
    'deposit-receipt_currency': 'Valuta Ricevuta',
    'withdraw-coin_address': 'Indirizzo per Prelievo Monete',
    'withdraw-coin_amount': 'Importo',
    'withdraw-handling_fee': 'Commissione di Servizio',
    'withdraw-expected': 'Importo previsto in accredito',
    'withdraw-btn-submit': 'Invia Prelievo',
    'withdraw-coin_withdrawal_record': 'Registro Prelievo Monete',
    'withdraw-num_of_receipt': 'Numero di Ricevute',
    'withdraw-select_addess': 'Per favore seleziona un indirizzo per chiamare',
    'order-position_holding': 'Posizione In Corso',
    'order-pending_orders': 'Ordini In Attesa',
    'order-history': 'Storia',
    'order-profit_loss': 'Profitto e Perdita',
    'order-current_margin': 'Margine Attuale',
    'order-risk_rate': 'Tasso di Rischio',
    'order-order_id': 'ID Ordini',
    'order-total_profit_loss': 'Profitto e Perdita Totale',
    'order-order_details': 'Dettagli Ordine',
    'order-margin': 'Margine',
    'order-take_profit': 'Prendi Profitto',
    'order-set_loss': 'Imposta Perdita',
    'order-set_TPSL': 'Imposta TP/SL',
    'order-close_position': 'Chiudi Posizione',
    'order-cancel_order': 'Annulla Ordine',
    'order-select_period': 'Seleziona Periodo',
    'order-btn_rise': 'Salita',
    'order-btn_fall': 'Discesa',
    'order-estimated_return': 'Rendimento Stimato',
    'order-purchase_amount': 'Importo Acquisto',
    'search-search_product': 'Cerca Prodotto',
    'search-search_result': 'Risultati della Ricerca',
    'trade-market_price': 'Prezzo di Mercato',
    'trade-price': 'Prezzo',
    'trade-quick_trade': 'Trading Veloce',
    'trade-please_enter_price': 'Per favore inserisci il prezzo',
    'trade-each_lots': 'Ogni Lotto',
    'trade-estimated_handling_fee': 'Commissione Stimata',
    'trade-estimated_margin': 'Margine Stimato',
    'trade-lots_text': 'Lotti (Passo: 0.001)',
    'trade-order_page': 'Pagina Ordini',
    'trade-your_order_has_been_confirmed': 'Il tuo ordine è stato confermato',
    'trade-multiplier': 'Moltiplicatore',
    'accHistory-financial_records': 'Registri Finanziari',
    'accHistory-records': 'Registri',

     'Res_Msg1': 'Per favore inserisci l\'email',
    'Res_Msg2': 'Formato email non valido',
    'Res_Msg3': 'Per favore inserisci la password',
    'Res_Msg4': 'La password e la conferma della password non corrispondono',
    'Res_Msg5': 'La password deve essere lunga almeno 6 caratteri',
    'Res_Msg6': 'Codice di verifica errato',
    'Res_Msg7': 'Per favore inserisci il codice di verifica',
    'Res_su': 'Registrazione riuscita',
    'Login_su': 'Login riuscito',
    'Loading': 'Nessun altro dato',
    'SDealCont_TopName': 'Consegna',
    'SDealCont_L1': 'Prezzo di acquisto',
    'SDealCont_L2': 'Prezzo attuale',
    'SDealCont_L3': 'Direzione',
    'SDealCont_L4': 'Quantità',
    'SDealCont_L5': 'Orario di consegna',
    'SDealCont_btn': 'Chiudi',
    'SDealCont_L6': 'Acquista Long',
    'SDealCont_L7': 'Vendi Short',
    'SDealCont_L8': 'Acquista',
    'SDealCont_L9': 'Vendi',
    'verify-verified': 'Verifica riuscita',
    'verify-notVerified': 'Verifica fallita',
    "加载中...": "Caricamento in corso...",
}
