<template>
  <div id="withdraw">
    <van-form @submit="submitWithdraw">
      <div
        class="bg-general v-mx-10 v-pb-10 v-rounded-bottom custom-bottom-shadow"
      >
        <van-cell-group inset class="bg-general">
          <div class="customBottomLine">
            <van-field
              readonly
              clickable
              :label="$t('bind-currency')"
              v-model="currencyLabel"
              :value="currencyLabel"
              :placeholder="currencyLabel"
              class="bg-general"
              right-icon="arrow"
              style="font-size: 3.2vw"
              @click="showCurrencyPicker = true"
            />
            <!-- Currency Picker -->
            <van-popup
              v-model:show="showCurrencyPicker"
              position="bottom"
              round
            >
              <van-picker
                show-toolbar
                :columns="currencyOptions"
                :cancel-button-text="$t('public-text-cancel')"
                :confirm-button-text="$t('public-text-confirm')"
                @cancel="showCurrencyPicker = false"
                @confirm="onConfirm"
              />
            </van-popup>
          </div>

          <div class="customBottomLine">
            <van-field
              readonly
              clickable
              :label="$t('withdraw-coin_address')"
              v-model="valueAddress"
              :value="valueAddress"
              :placeholder="
                valueAddress ? valueAddress : $t('withdraw-select_addess')
              "
              :class="
                valueAddress !== $t('withdraw-select_addess') ? '' : 'hasVal'
              "
              class="bg-general"
              right-icon="arrow"
              style="font-size: 3.2vw"
              @click="handleClick"
            />
            <!-- Address Picker -->
            <van-popup v-model:show="showAddressPicker" position="bottom" round>
              <van-picker
                show-toolbar
                :columns="columnAddress"
                :cancel-button-text="$t('public-text-cancel')"
                :confirm-button-text="$t('public-text-confirm')"
                @cancel="showAddressPicker = false"
                @confirm="onConfirmAddress"
              />
            </van-popup>
          </div>
          <div class="customBottomLine">
            <van-field
              style="font-size: 3.2vw"
              v-model="withdrawAmount"
              type="number"
              class="bg-general"
              :label="$t('withdraw-coin_amount') + `(USD)`"
              :placeholder="$t('withdraw-coin_amount')"
            />
          </div>
          <div class="customBottomLine">
            <van-field
              style="font-size: 3.2vw"
              v-model="remarks"
              class="bg-general"
              :label="$t('public-text-remarks')"
              :placeholder="$t('public-text-remarks')"
            />
          </div>

          <div class="v-px-10 v-text-12 k-text">
            <div class="flex items-center justify-between v-mt-10">
              <span>{{ $t("withdraw-handling_fee") }}</span>
              <span>{{ 0 }}</span>
            </div>
            <div class="flex items-center justify-between v-mt-10">
              <span>{{ $t("withdraw-expected") }}</span>
              <span>{{ withdrawAmount||0 }} {{ formatText(currencyLabel) }}</span>
            </div>
            <div class="flex items-center justify-between v-mt-10">
              <span>{{ $t("public-text-balance") }}</span>
              <span>{{ balance + ` USD` }}</span>
            </div>
          </div>
          <div class="flex justify-center v-my-20 v-px-12">
            <van-button
              size="normal"
              round
              class="w-[70%] btn-blue v-border-0"
              native-type="submit"
              >{{ $t("withdraw-btn-submit") }}</van-button
            >
          </div>
        </van-cell-group>
      </div>
    </van-form>
    <van-dialog
      v-model:show="showDialog"
      :message="$t('popupHint-set_up_collection_account')"
      show-cancel-button
      :cancel-button-text="$t('public-text-cancel')"
      :confirm-button-text="$t('public-text-confirm')"
      @confirm="onDialogConfirm"
      @cancel="onDialogCancel"
    />
    <!-- 历史记录列表 -->
    <WithdrawRecord
      :curType="$t('deposit-digitai-currency')"
      :submit-action="submitAction"
    />
  </div>
</template>

<script setup>
import { reactive, ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
// import { store } from "@/assets/store/data.js";
import { showToast } from "/node_modules/vant";
import WithdrawRecord from "@/pages/components/withdraw/components/withdrawRecord.vue";
import Server from "@/Axios/index.ts";

const { t } = useI18n();
const router = useRouter();
const storeA = useStore();
const userInfo = storeA.getters.GetInfoData;
const balance = userInfo.balance;
const showDialog = ref(false);
const submitAction = ref(1);

//currency selection
const currencyOptions = ref([]);
const currencyLabel = ref("");
const showCurrencyPicker = ref(false);

const showAccountPicker = ref(false);

const onCancel = () => {
  showAccountPicker.value = false;
};

const handleClick = () => {
  if (columnAddress.value.length === 0) {
    showDialog.value = true;
  } else {
    showAddressPicker.value = true;
  }
};

const onDialogConfirm = () => {
  showDialog.value = false;
  router.push({ name: "WithdrawType", query: { type: "2" } });
};

const onDialogCancel = () => {
  showDialog.value = false; // Close dialog if canceled
};

const onConfirm = (val) => {
  currencyLabel.value = val.selectedOptions[0].text || "";
  showCurrencyPicker.value = false;
};

// coin address selection
const columnAddress = ref([
  // { text: "aaaa", value: "aaaa" },
  // { text: "bbbb", value: "bbbb" },
]);
const valueAddress = ref("");
const walletChoice = ref("");
const showAddressPicker = ref(false);

onMounted(async () => {
  await GetData();
});

async function GetData() {
  // 货币选项
    const arr = await Server.post("/api/wallet/getRechargeSymbolList");
  arr.map((item) => {
    currencyOptions.value.push({ text: item.blockchain_name, value: item.coin });
  });
  currencyLabel.value = currencyOptions.value[0].text;

  // 提币地址
  const addressList = await Server.get("/api/wallet/getWalletList", {
    params: { type: "coin" },
  });
  addressList.map((item) => {
    columnAddress.value.push({
      text: item.blockchain_address,
      value: item.id,
    });
  });
  if (columnAddress.value.length > 0) {
    valueAddress.value = columnAddress.value[0].text;
    walletChoice.value = columnAddress.value[0].value;
  }
}

const onConfirmAddress = (val) => {
  valueAddress.value = val.selectedOptions[0].text || "";
  showAddressPicker.value = false;
};

const formatText = (str) => {
  return str.substring(0, 3);
};

const formatValue = (val) => {
  return val.toFixed(2);
};

const withdrawAmount = ref(null);
const remarks = ref("");
const handlingFee = computed(() => (withdrawAmount.value * 0.1).toFixed(2));
const expectedValue = computed(() => (withdrawAmount.value * 0.2).toFixed(2));

const submitWithdraw = async () => {
  if (!walletChoice.value || !withdrawAmount.value) {
    showToast({
      message: t("systemHint-missing_params-or-val-error"),
      icon: "fail",
    });
  } else {
    try {
      await Server.post("/api/wallet/withdraw", {
        amount: withdrawAmount.value,
        wallet_id: walletChoice.value,
        remark: remarks.value,
        type:'coin'
      });
      showToast({
        message: t("systemHint-operation_successful"),
        icon: "success",
      });

        submitAction.value++;
        await storeA.dispatch('GetUserData')
    } catch (error) {
      console.error(error);
    }
  }
};

const clearForm = () => {
  valueAddress.value = t("withdraw-select_addess");
  withdrawAmount.value = "";
  remarks.value = "";
};
</script>

<style scoped>
.box-shadow-bottom {
  box-shadow: 0 2px 4px rgba(161, 161, 161, 0.09);
}
</style>
