<template>
  <div style="min-height: 100vh; flex: 1" v-loading="$store.getters.GetLoding">
    <topNavBar
      :leftLanguageIcon="true"
      headerTitle="FBS"
      :rightIcon="true"
      :iconName="'Search'"
      :rightIconStyle="'height:8.4444vw; width:8vw; border-radius: 2vw; box-shadow: 0 0 3vw #a1a1a12e;'"
      :handleClick="navToSearch"
    />
    <div id="home" class="v-px-6" style="margin-bottom: 200px">
      <div class="v-mt-10 v-mb-10 v-px-6 flex v-text-14 overflow-auto headTop">
        <div
          :class="['headtab', { active: indexTab === index + 1 }]"
          v-for="(time, index) in TabArr"
          :key="index"
          @click="setTabBg(index, time)"
        >
          {{ time }}
        </div>
      </div>

      <van-list
        v-model:loading="loading"
        :finished="finished"
        :finished-text="$t('Loading')"
        @load="onLoad"
        :loading-text="$t('加载中...')"
        id="list"
        style="min-height: 80vh"
      >
        <van-cell v-for="(item, index) in ArrData" :key="index">
          <div class="base-shadow list-main" @click="$SkipPage(item)">
            <div style="display: flex; width: 30%">
              <div style="display: flex; align-items: center">
                <img
                  :src="item.logo"
                  style="width: 37.475px; height: 37.475px"
                />
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  padding-left: 2.66667vw !important;
                  font-size: 3.26667vw;
                "
              >
                {{ item.symbol }}
              </div>
            </div>
            <div style="display: flex; align-items: center">
              <div class="" v-if="item.is_close == 0">
                <div style="text-align: center; font-size: 3.7vw">
                  <div>{{ Number(item.last_price).toFixed(2) }}</div>
                  <div
                    :class="{
                      positive: item.close >= item.open,
                      negative: item.close < item.open,
                    }"
                  >
                    {{ item.increase > 0 ? "+" : item.increase == 0 ? "" : ""
                    }}{{ item.increase + "%" }}
                    <el-icon v-if="item.close > item.open">
                      <CaretTop />
                    </el-icon>

                    <el-icon v-if="item.close < item.open">
                      <CaretBottom />
                    </el-icon>
                  </div>
                </div>
              </div>
              <div class="flex-1 v-w-110" v-else>
                <div class="closed">{{ $t("public-text-close") }}</div>
              </div>
            </div>
            <div style="display: flex; align-items: center">
              <div
                :id="'k-line-chart' + (index + 1)"
                class="klChart"
                style="text-align: center"
              >
                <van-loading class="_loading" />
              </div>
            </div>
          </div>
        </van-cell>
      </van-list>
    </div>
    <tabBar />
  </div>
</template>

<script setup>
import {
  onMounted,
  ref,
  nextTick,
  watch,
  computed,
  onUnmounted,
  onBeforeUnmount,
  onActivated,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import topNavBar from "@/pages/components/common/topNavBar.vue";
import tabBar from "@/pages/components/common/tab-bar.vue";
import { getAreaData } from "@/api/chart";
import { useStore } from "vuex";
import { CaretBottom, CaretTop } from "@element-plus/icons-vue";
import config from "../../../config.ts";
import axios from "axios";

const router = useRouter();
const ETPName = ref("");
const { t } = useI18n();
const indexTab = ref();
const ArrData = ref([]);
const loading = ref(false);
const finished = ref(false);
const TabArr = ref(["Forex", "CRYPTO", "Metal", "CFD", "US"]);
const store = useStore();
const socket = ref();
let PageIndex = 1;
let cancelTokenSource = axios.CancelToken.source();

onMounted(async () => {
  store.commit("SetLoading", true);
  await GetArrData();
  setupWebSocket();
  onLoad();
});

onUnmounted(() => {
  if (socket.value) {
    socket.value.close();
  }
});

function setTabBg(i, open) {
  if (i + 1 == indexTab.value) {
    indexTab.value = "";
    ETPName.value = "";
  } else {
    indexTab.value = i + 1;
    ETPName.value = open;
  }
}

watch(ETPName, async () => {
  cancelTokenSource.cancel("请求断开");
  store.commit("SetLoading", true);
  PageIndex = 1;
  ArrData.value = [];
  loading.value = false;
  finished.value = false;
  cancelTokenSource = axios.CancelToken.source();
  await GetArrData();
});

const navToSearch = () => {
  router.push({ name: "SearchProduct" });
};

async function onLoad() {
  PageIndex++;
  await GetArrData();
}

// 获取数据
async function GetArrData() {
  console.log(cancelTokenSource.token);
  loading.value = true;
  const arr = await getAreaData(
    ETPName.value,
    PageIndex,
    10,
    cancelTokenSource
  );
  loading.value = false;
  if (arr) {
    ArrData.value.push(...arr);

    // 数据全部加载完成
    if (arr.length < 10) {
      finished.value = true;
    }
  } else {
    finished.value = true;
    loading.value = true;
  }
}

function setupWebSocket() {
  socket.value = new WebSocket("wss://api.hzwh888.vip:2348"); //new WebSocket('wss://134.122.200.33:2348'); // 使用本地开发服务器的 WebSocket URL
  socket.value.onmessage = (event) => {
    const data = JSON.parse(event.data);
    updateData(data);
  };

  socket.value.onopen = () => {
    console.log("WebSocket 连接已建立");
  };
}

function updateData(newData) {
  // 将 newData 转换为查找对象（哈希表），以 symbol 为键
  const newDataMap = newData.reduce((acc, dataItem) => {
    acc[dataItem.symbol] = dataItem;
    return acc;
  }, {});

  // 更新 lineData 数组
  ArrData.value = ArrData.value.map((item) => {
    // 查找对应的更新数据
    const updatedItem = newDataMap[item.symbol];
    // 如果存在更新的数据，合并原数据和新数据；否则，保持原数据不变
    return updatedItem ? { ...item, ...updatedItem } : item;
  });
}

onBeforeUnmount(() => {
  try {
    cancelTokenSource.cancel("请求断开");
    socket.value.close();
  } catch (e) {
    console.log(e);
  }
});
</script>

<style scoped>
.headTop::-webkit-scrollbar {
  display: none;
  /* 隐藏 Chrome, Safari 和 Opera 中的滚动条 */
}

.headtab {
  margin-right: 2.66667vw;
  padding: 1vw 4.26667vw;
  border-radius: 8vw;
  font-weight: 800;
  font-size: 3.2vw;
  /* background: #f3f4f6;
  color: black; */
  white-space: nowrap;
}

.klChart {
  width: 24vw;
  height: 6.4vw;
  text-align: center;
}

.closed {
  font-size: 3.2vw;
  /* color: #fff; */
  border-radius: 2.13333vw;
  font-weight: 700;
  text-align: center;
  padding: 1.06667vw 1.6vw;
}

.van-cell {
  padding: 0 !important;
}
</style>
