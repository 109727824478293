import axios from "axios";
import config from "../../config.ts"
import { getToken } from "@/assets/store/util.js";
import { showToast } from "vant";
import store from "@/assets/store/store.ts";

const showMessage = (icon, message) => {
  showToast({
    type: icon.toLowerCase(),
    message,
    duration: 1500,
  });
};

const Server = axios.create({
  baseURL: config.apiUrl,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

Server.interceptors.request.use(
    (req) => {
        const token = getToken();

        if(token){
            req.headers.Authorization = `${token}`
        }
        req.headers["language"] = localStorage.getItem("Lang");

        return req;
    },
    (error) => {
        return Promise.reject();
    }
)

Server.interceptors.response.use(
    (resp) => {        
        store.commit('SetLoading', false);
        const { code,data,msg } = resp.data;
        switch (code){
            case -1:
                showMessage("fail", msg);
                return new Promise(() => {});
            default:
                return data;
        }
    },
    (error) => {
        
    }
)

export default Server;