<template>
    <div class="bg-main v-m-10 v-p-10">
        <div class="k-text v-mx-10 v-text-14 font-bold">
            {{ $t("withdraw-coin_withdrawal_record") }}
        </div>
        <van-list v-model:loading="loading" :finished="finished" @load="onLoad" :finished-text="$t('Loading')"
            :loading-text="$t('加载中...')">
            <van-cell class="bg-general v-p-10 v-text-12 v-rounded-8 base-shadow v-mt-10"
                v-for="(item, index) in filteredWithdrawRecords" :key="index">
                <div class="flex justify-between">
                    <div class="flex-1 text-left">
                        <div class="text-color-7">
                            {{ $t("withdraw-coin_amount") }}
                        </div>
                        <div class="k-text">{{ parseFloat(item.amount).toFixed(2) }}</div>
                    </div>
                    <div class="flex-1 text-center">
                        <div class="text-color-7">
                            {{ $t("withdraw-num_of_receipt") }}
                        </div>
                        <div class="k-text">
                            {{ parseFloat(item.details?.act_amount || "0").toFixed(2) }}
                        </div>
                    </div>
                    <div class="flex-1 text-right">
                        <div class="text-color-7">
                            {{ $t("deposit-recharge_unit") }}
                        </div>
                        <div class="k-text">
                            {{
                            item.details?.bank &&
                            item.details?.bank.type &&
                            item.details?.bank.type === "coin"
                            ? "USDT"
                            : "USD"
                            }}
                        </div>
                    </div>
                </div>

                <div class="flex justify-between">
                    <div class="flex-1 text-left">
                        <div class="text-color-7">
                            {{ $t("withdraw-handling_fee") }}
                        </div>
                        <div class="k-text">
                            {{ parseFloat(item.details?.service_charge || "0").toFixed(2) }}
                        </div>
                    </div>
                    <div class="flex-1 text-center">
                        <div class="text-color-7">
                            {{ $t("deposit-recharge_status") }}
                        </div>
                        <div class="k-text">
                            {{
                            item.status === 0
                            ? $t("verify-infoAuth")
                            : item.status === 1
                            ? $t("verify-verified")
                            : item.status === 2
                            ? $t("verify-notVerified")
                            : ""
                            }}
                        </div>
                    </div>
                    <div class="flex-1 text-right">
                        <div class="text-color-7">
                            {{ $t("public-text-date") }}
                        </div>
                        <div class="k-text">
                            {{ new Date(item.ctime * 1000).toLocaleString() }}
                        </div>
                    </div>
                </div>

                <div class="flex justify-between">
                    <div class="flex-1 text-left">
                        <div class="text-color-7">
                            {{ $t("withdraw-coin_address") }}
                        </div>
                        <div class="k-text">
                            {{
                            item.details?.bank &&
                            item.details?.bank.type &&
                            item.details?.bank.type === "coin"
                            ? item.details?.bank?.blockchain_address
                            : item.details?.bank?.bank_address
                            }}
                        </div>
                    </div>
                </div>

                <div class="">
                    <div class="flex-1 text-left">
                        <div class="text-color-7">
                            {{ $t("public-text-remarks") }}
                        </div>
                        <div class="k-text">{{ item.details.remark || "" }}</div>
                    </div>
                </div>
            </van-cell>
        </van-list>
    </div>
</template>

<script setup>
import { reactive, ref, computed, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { store } from "@/assets/store/data.js";
import Server from "@/Axios/index.ts";

const props = defineProps({
  curType: {
    type: String,
    required: true,
  },
  submitAction: Boolean,
});

const { t } = useI18n();
const router = useRouter();
const withdrawRecords = ref([]);
const loading = ref(false);
const finished = ref(false);
let Pageindex = 1;

onMounted(async () => {
  await GetData();
});

// async function GetData() {
//   try {
//     withdrawRecords.value =
//         (await Server.post("/api/wallet/record", { page: Pageindex, size: "10" })) ||
//       [];
//   } catch (error) {
//     console.error(error);
//   }
// }

async function GetData() {
    loading.value = true;
    const arr =
        (await Server.post("/api/wallet/record", {
            page: Pageindex,
            size: "10",
        })) || [];

    if (arr) {
        withdrawRecords.value.push(...arr.filter(
            (record) => record?.type === "withdraw"
        ))
        // 数据全部加载完成
        if (arr.length < 10) {
            finished.value = true;
        }
        loading.value = false;
    }
}

const onLoad = async () => {
    Pageindex++;
    await GetData();
    
};

watch(
  () => props.submitAction,
  async (hasAction) => {
      Pageindex = 1;
      withdrawRecords.value = [];
      loading.value = false;
      finished.value = false;
    await GetData();
  }
);

//根据type传递数据
const filteredWithdrawRecords = computed(() => {
  return withdrawRecords.value.filter((record) => record?.type === "withdraw");
});
</script>

<style scoped></style>
