<template>
  <div v-if="loading">
    <van-skeleton class="mt-5" title :row="3" />
    <van-skeleton class="v-mt-10" title :row="3" />
  </div>
  <van-list
    id="vanList"
    v-model:loading="loading"
    :finished="finished"
    :finished-text="$t('Loading')"
    @load="onLoad"
    :loading-text="$t('加载中...')"
    v-else-if="holdingDataList.length > 0"
  >
    <van-cell
      v-for="(item, index) in holdingDataList"
      :key="index"
      class="customList"
    >
      <div
        class="list-item v-rounded-10 v-mt-8 v-mb-8 v-p-16 base-shadow"
        @click="openOrderPopup(item)"
      >
        <div class="flex justify-between items-end">
          <div class="k-text v-text-14">{{ item.symbol }}</div>
        </div>
        <div class="flex items-center justify-between v-text-12 v-mt-4">
          <div class="k-text" style="width: 60%; text-align: left">
            <span>{{ Number(item.open_price).toFixed(2) }}</span>
            <span class="px-2">-></span>
            <span>{{
              props.orderStatus == 2
                ? Number(item.close_price).toFixed(2)
                : Number(item.now_price).toFixed(2)
            }}</span>
          </div>

          <div
            class="flex trade-tag"
            :class="item.type == 2 ? 'sell-tag' : 'buy-tag'"
          >
            <div
              class="v-px-8 text-white"
              :class="item.type == 2 ? 'bg-kline-down' : 'bg-kline-up'"
            >
              {{
                item.number == 0
                  ? item.type == 1
                    ? $t("SDealCont_L6")
                    : $t("SDealCont_L7")
                  : item.type == 1
                  ? $t("SDealCont_L8")
                  : $t("SDealCont_L9")
              }}
            </div>
            <div
              class="v-px-6"
              :class="item.type == 2 ? 'text-kline-down' : 'text-kline-up'"
              v-if="item.number != 0"
            >
              {{ Number(item.share).toFixed(3) }} {{ $t("public-text-lots") }}
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between v-mt-2">
          <div class="sub-text v-text-12 text-left">
            <div>
              <span>{{ $t("order-order_id") }}</span>
              <span> #{{ item.id }}</span>
            </div>
            <div class="">{{ $formatTimestamp(item.open_time) }}</div>
          </div>
          <div
            class="v-text-sm"
            :class="item.type == 1 ? 'text-kline-up' : 'text-kline-down'"
          >
            {{ Number(item.profits).toFixed(3) || 0 }}
          </div>
        </div>
      </div>
    </van-cell>
  </van-list>

  <div v-else>
    <noRecord />
  </div>

  <!-- 数据内容弹窗 -->
  <van-popup
    v-model:show="showPopup"
    position="bottom"
    round
    closeable
    :icon-prefix="'van-icon'"
    :close-icon="'cross k-text font-bold bg-icon v-p-6 v-text-14 v-rounded-4'"
    style="z-index: 2030; padding: 3.73333vw"
    :style="`height: ${orderStatus !== 0 ? '40%' : '80%'}`"
  >
    <div class="popup-content">
      <div class="v-text-16 k-text font-bold v-mb-20">
        {{ $t("order-order_details") }}
      </div>
      <div class="flex justify-between items-end">
        <div class="k-text">{{ selectedItem?.symbol }}</div>
      </div>
      <div class="flex items-center justify-between v-text-12 my-2">
        <div class="k-text">
          <span>{{ Number(selectedItem?.open_price).toFixed(2) }}</span>
          <span class="px-2">-></span>
          <span>{{ Number(selectedItem.now_price).toFixed(2) }}</span>
        </div>
        <div
          class="flex trade-tag"
          :class="selectedItem.type == 2 ? 'sell-tag' : 'buy-tag'"
        >
          <div
            class="v-px-8 text-white"
            :class="selectedItem.type == 2 ? 'bg-kline-down' : 'bg-kline-up'"
          >
            {{
              selectedItem.number == 0
                ? selectedItem.type == 1
                  ? $t("SDealCont_L6")
                  : $t("SDealCont_L7")
                : selectedItem.type == 1
                ? $t("SDealCont_L8")
                : $t("SDealCont_L9")
            }}
          </div>
          <div
            class="v-px-6"
            :class="
              selectedItem.type == 2 ? 'text-kline-down' : 'text-kline-up'
            "
            v-if="selectedItem.number != 0"
          >
            {{ Number(selectedItem.share).toFixed(3) }}
            {{ $t("public-text-lots") }}
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between">
        <div class="text-[#cdcdcd] v-text-12 text-left">
          <div>
            <span>{{ $t("order-margin") }}:</span>
            <span>{{ selectedItem?.origin_caution_money }}</span>
          </div>
          <div>
            <span>{{ $t("withdraw-handling_fee") }}:</span>
            <span>{{ selectedItem?.trade_fee }}%</span>
          </div>
          <!-- <div>
            <span>{{ $t("order-set_loss") }}:</span>
            <span>{{ selectedItem?.targetLossPrice }}</span>
          </div>
          <div>
            <span>{{ $t("order-take_profit") }}:</span>
            <span>{{ selectedItem?.targetProfitPrice }}</span>
          </div> -->
          <div>
            <span>{{ $t("order-order_id") }}</span>
            <span> #{{ selectedItem?.id }}</span>
          </div>
          <div>{{ selectedItem?.createAt }}</div>
          <div>{{ selectedItem?.updatedAt }}</div>
        </div>
        <div
          class="v-text-sm"
          :class="isProfit(selectedItem) ? 'text-kline-up' : 'text-kline-down'"
        >
          {{ selectedItem?.profits }}
        </div>
      </div>

      <!-- Position holding content -->
      <div v-if="orderStatus == 0">
        <div
          class="bg-shadow v-py-20 v-px-6 v-rounded-10 v-mt-20 flex items-center justify-between"
        >
          <div class="v-mx-8">
            <div class="flex items-center justify-between">
              <span class="k-text v-pr-10">{{ $t("order-take_profit") }}</span>
              <van-stepper
                v-model="profitVal"
                :min="0"
                input-width="14.888vw"
                button-size="7.333vw"
              />
            </div>
            <div class="flex items-center justify-between v-mt-20">
              <span class="k-text v-pr-10">{{ $t("order-set_loss") }}</span>
              <van-stepper
                v-model="lossVal"
                :min="0"
                input-width="14.888vw"
                button-size="7.333vw"
              />
            </div>
          </div>
          <van-button
            size="small"
            round
            style="width: fit-content"
            class="btn-blue v-h-30 v-text-12 text-white v-border-0 v-mx-8"
            @click="setTargetPrice"
            >{{ $t("order-set_TPSL") }}</van-button
          >
        </div>
        <div class="flex items-center v-mt-8">
          <van-button
            size="normal"
            round
            class="btn-blue v-text-12 mx-auto v-mt-10 v-h-36 flex-1 v-rounded-xl v-mr-8 v-border-0"
            @click="closePosition"
            >{{ $t("order-close_position") }}
          </van-button>
        </div>
      </div>

      <!-- Pending order content -->
      <div v-if="orderStatus == 1">
        <div class="flex items-center v-mt-8">
          <van-button
            size="normal"
            round
            class="btn-blue v-text-12 mx-auto v-mt-10 v-h-36 flex-1 v-rounded-xl v-mr-8 v-border-0"
            @click="cancelOrder"
            >{{ $t("order-cancel_order") }}
          </van-button>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { showToast } from "/node_modules/vant";
import noRecord from "@/pages/components/common/noRecord.vue";
import { useStore } from "vuex";

const props = defineProps({
  orderStatus: {
    type: Number,
    default: 0,
  },
  ListData: {
    type: Array,
    default: () => [],
  },
});

const store = useStore();

const { t } = useI18n();
const router = useRouter();
const isSell = (item) => item.tradeStatus == t("public-text-sell");
const isProfit = (item) => item.profits > 0;
const profitVal = ref(0);
const lossVal = ref(0);

const loading = ref(false);
const finished = ref(false);
const emits = defineEmits(["toggle-popup"]);
let PageIndex = 1;
const holdingDataList = ref([]);

async function onLoad() {
  PageIndex++;
  await GetArrData();
  // 加载状态结束
  loading.value = false;
}

async function GetArrData() {
  try {
    const type =
      props.orderStatus == 0
        ? "hold"
        : props.orderStatus == 1
        ? "pending"
        : props.orderStatus == 2
        ? "history"
        : "mic";
    const arr = (
      await store.dispatch("GetOrderList", {
        data: { page: PageIndex, per_page: 6, type: type },
      })
    ).list;

    if (arr) {
      holdingDataList.value.push(...arr);
      // 数据全部加载完成
      if (arr.length < 10) {
        finished.value = true;
      }
    } else {
      loading.value = false;
    }
  } catch (e) {
    console.log(e);
  }
}

onMounted(async () => {
  watch(showPopup, (newValue) => {
    if (!newValue) {
      emits("toggle-popup", false); // 通知父组件关闭弹出窗口
    }
  });

  await GetArrData();
  setupWebSocket();
});
const showPopup = ref(false);
const selectedItem = ref(null);

const openOrderPopup = (item) => {
  console.log(item);
  selectedItem.value = item;
  profitVal.value = item.target_profit_price;
  lossVal.value = item.stop_loss_price;
  showPopup.value = true;
  emits("toggle-popup", true); // 通知父组件打开弹出窗口
};

const closePopup = () => {
  showPopup.value = false;
};

const setTargetPrice = async () => {
  await store.dispatch("UpLossProfit", {
    data: {
      order_no: selectedItem.value.order_no,
      stop_loss_price: lossVal.value,
      stop_win_price: profitVal.value,
    },
  });
  PageIndex = 1;
  holdingDataList.value = [];
  GetArrData();
  //TODO: set value去哪里？？
  showToast({
    message: t("systemHint-set_successful"),
    icon: "success",
    zIndex: 3000,
  });
};
const closePosition = async () => {
  if (props.orderStatus == 0) {
    await store.dispatch("Pingc", {
      data: { order_no: selectedItem.value.order_no },
    });
  } else if (props.orderStatus == 1) {
    await store.dispatch("Pingc", {
      data: { order_no: selectedItem.value.order_no },
    });
  }
  PageIndex = 1;
  holdingDataList.value = [];
  GetArrData();
  showToast({
    message: t("systemHint-operation_successful"),
    icon: "success",
  });
  showPopup.value = false;
};

const cancelOrder = () => {
  showToast({
    message: t("systemHint-operation_successful"),
    icon: "success",
  });
  showPopup.value = false;
};

// 监听orderId的变化来重置profitVal
watch(
  () => selectedItem.value?.orderId,
  (newOrderId, oldOrderId) => {
    if (newOrderId !== oldOrderId) {
      profitVal.value = 0;
      lossVal.value = 0;
    }
  }
);

const socket = ref();

function setupWebSocket() {
  socket.value = new WebSocket("wss://api.hzwh888.vip:2348"); //new WebSocket('wss://api.jiekourapi.com:2348 '); // 使用本地开发服务器的 WebSocket URL
  socket.value.onmessage = (event) => {
    const data = JSON.parse(event.data);
    if (props.orderStatus == 2 || props.orderStatus == 3) {
      return;
    }

    updateData(data);
  };

  socket.value.onopen = () => {
    console.log("WebSocket 连接已建立");
  };
}

function updateData(newData) {
  // 将 newData 转换为查找对象（哈希表），以 symbol 为键
  const newDataMap = newData.reduce((acc, dataItem) => {
    acc[dataItem.symbol] = dataItem;
    return acc;
  }, {});

  // 更新 lineData 数组
  holdingDataList.value.map(async (item, index) => {
    // 查找对应的更新数据

    try {
      if (newDataMap[item.symbol]) {
        holdingDataList.value[index].now_price = newDataMap[item.symbol].close;
        if (holdingDataList.value[index].number != 0) {
          if (holdingDataList.value[index].status == 3) {
            return;
          }
          if (holdingDataList.value[index].type == 1) {
            holdingDataList.value[index].profits = Number(
              (newDataMap[item.symbol].close -
                holdingDataList.value[index].open_price) *
                (holdingDataList.value[index].share *
                  newDataMap[item.symbol].multiplier)
            ).toFixed(3);
            if (
              holdingDataList.value[index].profits <
              holdingDataList.value[index].origin_caution_money * -1
            ) {
              PageIndex = 0;
              // 加载状态结束
              loading.value = false;
              finished.value = false;
              holdingDataList.value = [];
              await GetArrData();
              return;
            }
          } else {
            holdingDataList.value[index].profits = Number(
              (holdingDataList.value[index].open_price -
                newDataMap[item.symbol].close) *
                (holdingDataList.value[index].share *
                  newDataMap[item.symbol].multiplier)
            ).toFixed(3);
          }
        } else {
          if (holdingDataList.value[index].type == 1) {
            holdingDataList.value[index].profits = Number(
              newDataMap[item.symbol].close -
                holdingDataList.value[index].open_price
            ).toFixed(3);
          } else {
            holdingDataList.value[index].profits = Number(
              holdingDataList.value[index].open_price -
                newDataMap[item.symbol].close
            ).toFixed(3);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  });
}

onBeforeUnmount(() => {
  socket.value.close();
});
</script>

<style scoped>
.trade-tag {
  border-radius: 1.2vw;
  overflow: hidden;
  transform: scale(0.9);
}
</style>
