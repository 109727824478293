<template>
  <div id="deposit">
    <van-form @submit="onSubmit">
      <van-cell-group class="bg-main">
        
        <!-- 上传凭证 -->
        <div class="v-my-10 v-px-20">
          <div class="k-text v-mt-18 v-text-12">
            {{ $t("deposit-recharge_amount") }}
          </div>
          <div class="input-bg base-input v-w-full v-mt-10">
            <van-field
              class="input-bg v-mx-10"
              style="align-items: center; font-size: 3.26667vw"
              v-model="valueRecharge"
              :placeholder="$t('deposit-recharge-placeholder')"
            />
          </div>
          <div class="flex flex-col items-center v-mt-20">
            <van-uploader
              class="mx-auto"
              v-model="certImage"
              :max-count="1"
              :after-read="afterRead"
              @click-preview="onClickPreview"
            ></van-uploader>
            <van-popup position="center">
              <van-image
                :src="previewCertImageSrc"
                style="object-fit: contain"
              />
            </van-popup>
          </div>
          <div class="k-text text-center v-text-12 px-5">
            {{ $t("doposit-upload-cert") }}
          </div>
        </div>

        <div class="flex justify-center v-h-40 mx-auto w-[80%] v-my-10">
          <van-button
            class="btn-blue v-border-0"
            size="large"
            round
            native-type="submit"
            >{{ $t("public-btn-submit") }}</van-button
          >
        </div>
      </van-cell-group>
    </van-form>
  </div>

  <!-- 历史记录列表 -->
  <DepositRecord
    :curType="$t('deposit-bank-card')"
    :submit-action="submitAction"
  />
</template>

<script setup>
import { reactive, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { store } from "@/assets/store/data.js";
import { showToast } from "/node_modules/vant";
import DepositRecord from "@/pages/components/deposit/components/depositRecord.vue";
import Server from "@/Axios/index.ts";

const { t } = useI18n();
const router = useRouter();
const currencyList = ref([]);
const currencyLabel = ref("");
const showCurrencyPicker = ref(false);
const valueRecharge = ref();
const checked = ref("default-recharge");
const certImage = ref([]);
const previewCertImageSrc = ref("");
const submitAction = ref(1);

const onConfirm = (val) => {
  currencyLabel.value = val.selectedValues[0];
  showCurrencyPicker.value = false;
};

const imageUrl = ref("");

const afterRead = async (file) => {
  certImage.value = [file];

  const formData = new FormData();
  formData.append("file", certImage.value[0].file);
  try {
    const response = await Server.post("/api/common/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    imageUrl.value = response.list.slice(1);
  } catch (error) {
    console.error("Error uploading file:", error);
  }
};

const onClickPreview = (file) => {
  previewCertImageSrc.value = file.content || file.url;
};

const onSubmit = async () => {
  if (
    // !currencyLabel.value ||
    !valueRecharge.value ||
    isNaN(valueRecharge.value) ||
    certImage.value.length < 1
  ) {
    showToast({
      message: t("systemHint-missing_params-or-val-error"),
      icon: "fail",
    });
    return
  }
    try {
        // submitAction.value = true;
        const response = await Server.post("/api/wallet/deposit", {
            symbol: 'USD',
            amount: valueRecharge.value,
            imgs: imageUrl.value,
            type: "bank"
        });
        showToast({
            message: t("systemHint-operation_successful"),
            icon: "success",
        });
        // clearForm();
        submitAction.value++;
    } catch (error) {
    console.error(error);
    }
};

const clearForm = () => {
  currencyLabel.value = "";
  valueRecharge.value = "";
  certImage.value = [];
};

// 币种类显示(vant picker使用)
const currencyFieldName = {
  text: "symbol",
  value: "symbol",
};

onMounted(async () => {
  try {
    currencyList.value = await Server.post("/api/wallet/getRechargeSymbolList");
    console.log();

    currencyLabel.value = currencyList.value[0]?.symbol || "";
  } catch (error) {
    console.error(error);
  }
});
</script>

<style scoped>
.base-input {
  border-radius: 5.33333vw;
  background: #f8f8f8;
  padding-top: 2.13333vw;
  padding-bottom: 2.13333vw;
  height: 10.66667vw;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}
</style>
