import { createRouter, createWebHashHistory } from "vue-router";
import LoginIndex from "@/pages/login/loginIndex.vue";
import RegisterIndex from "@/pages/login/registerIndex.vue";
import ForgotPassword from "@/pages/login/forgotPassword.vue";
import HomeIndex from "@/pages/tabBarPages/homeIndex.vue";
import OrderIndex from "@/pages/tabBarPages/order/orderIndex.vue";
import TradeIndex from "@/pages/tabBarPages/tradeIndex.vue";
import NewsIndex from "@/pages/tabBarPages/newsIndex.vue";
import NewsDetails from "@/pages/components/news/newsDetail.vue";
import MineIndex from "@/pages/tabBarPages/mine/mineIndex.vue";
import AccountHistory from "@/pages/components/mine/accountHistory.vue";
import Deposit from "@/pages/components/deposit/depositIndex.vue";
import Withdraw from "@/pages/components/withdraw/withdrawIndex.vue";
import Wallet from "@/pages/components/mine/wallet/walletIndex.vue";
import VerifyName from "@/pages/components/mine/verifyName/verifyName.vue";
import VerifyAuth from "@/pages/components/mine/verifyName/verifyAuth.vue";
import InviteFriend from "@/pages/components/mine/inviteFriend.vue";
import ChangePassword from "@/pages/components/mine/changePassword.vue";
import OnlineService from "@/pages/components/mine/onlineService.vue";
import ComplaintEmail from "@/pages/components/mine/complaintEmail.vue";
import Announcement from "@/pages/components/mine/announcementIndex.vue";
import LanguageSelect from "@/pages/components/mine/languageSelect.vue";
import Other from "@/pages/components/mine/otherIndex.vue";
import BindBank from "@/pages/components/mine/wallet/bindPages/bindBankIndex.vue";
import BindCurrency from "@/pages/components/mine/wallet/bindPages/bindCurrencyIndex.vue";
import WithdrawType from "@/pages/components/mine/wallet/bindPages/withdrawType.vue";
import SearchProduct from "@/pages/components/searchProduct.vue";
import SysIndex from "@/pages/SysIndex.vue";
import config from "../../../config.ts"

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/pages/login/loginIndex", // 登入
      name: "LoginIndex",
      component: LoginIndex,
    },
    {
      path: "/pages/login/registerIndex", // 注册
      name: "RegisterIndex",
      component: RegisterIndex,
    },
    {
      path: "/pages/login/forgotPassword", // 忘记密码
      name: "ForgotPassword",
      component: ForgotPassword,
    },
    {
      path: "/", // 首页
      name: "HomeIndex",
      component: HomeIndex,
    },
    {
      path: "/SysIndex",
      name: "SysIndex",
      component: SysIndex,
    },
    {
      path: "/pages/tabBarPages/order/orderIndex", // Order
      name: "OrderIndex",
      component: OrderIndex,
    },
    {
      path: "/pages/tabBarPages/tradeIndex", // trade
      name: "TradeIndex",
      component: TradeIndex,
      meta: { keepAlive: true },
    },
    {
      path: "/pages/tabBarPages/newsIndex", // 新闻
      name: "NewsIndex",
      component: NewsIndex,
    },
    {
      path: "/pages/components/news/newsDetail", // 新闻
      name: "NewsDetails",
      component: NewsDetails,
      props: (router) => ({ id: router.query.id }),
    },
    {
      path: "/pages/tabBarPages/mine/mineIndex", // 我的
      name: "MineIndex",
      component: MineIndex,
    },
    {
      path: "/pages/components/mine/accountHistory", // 账户详情
      name: "AccountHistory",
      component: AccountHistory,
    },
    {
      path: "/pages/components/deposit/depositIndex", // 存款
      name: "Deposit",
      component: Deposit,
    },
    {
      path: "/pages/components/withdraw/withdrawIndex", // 提现
      name: "Withdraw",
      component: Withdraw,
    },
    {
      path: "/pages/components/mine/wallet/walletIndex", // 钱包
      name: "Wallet",
      component: Wallet,
    },
    {
      path: "/pages/components/mine/verifyName/verifyName", // 验证身份
      name: "VerifyName",
      component: VerifyName,
    },
    {
      path: "/pages/components/mine/verifyName/verifyAuth", // 验证身份
      name: "VerifyAuth",
      component: VerifyAuth,
    },
    {
      path: "/pages/components/mine/inviteFriend", // 邀请好友
      name: "InviteFriend",
      component: InviteFriend,
    },
    {
      path: "/pages/components/mine/changePassword", // 更换密码
      name: "ChangePassword",
      component: ChangePassword,
    },
    {
      path: "/pages/components/mine/onlineService", // 客服
      name: "OnlineService",
      component: OnlineService,
    },
    {
      path: "/pages/components/mine/complaintEmail", // 投诉邮箱
      name: "ComplaintEmail",
      component: ComplaintEmail,
    },
    {
      path: "/pages/components/mine/announcementIndex", // 通知
      name: "Announcement",
      component: Announcement,
    },
    {
      path: "/pages/components/mine/languageSelect", // 更换语言
      name: "LanguageSelect",
      component: LanguageSelect,
    },
    {
      path: "/pages/components/mine/otherIndex", // 其他
      name: "Other",
      component: Other,
    },
    {
      path: "/pages/components/mine/wallet/bindPages/bindBankIndex", // 已绑定银行卡页面
      name: "BindBank",
      component: BindBank,
    },
    {
      path: "/pages/components/mine/wallet/bindPages/bindCurrencyIndex", // 绑定银行卡
      name: "BindCurrency",
      component: BindCurrency,
    },
    {
      path: "/pages/components/mine/wallet/withdrawType",
      name: "WithdrawType",
      component: WithdrawType,
      props: (router) => ({ type: router.query.type }),
    },
    {
      path: "/pages/components/searchProduct",
      name: "SearchProduct",
      component: SearchProduct,
    },
  ],
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);

    const token = localStorage.getItem("token");
    if (token) {
      if (
        to.path == "/pages/login/loginIndex" ||
        to.path == "/pages/login/registerIndex"
      ) {
        next("/");
      } else {
        next();
      }
    } else {
      if (
        to.path == "/pages/login/loginIndex" ||
        to.path == "/" ||
        to.path == "/pages/login/registerIndex" ||
        to.path == "/pages/login/forgotPassword" ||
        to.path == "/pages/tabBarPages/newsIndex" ||
        to.path == "/pages/components/mine/languageSelect" ||
        to.path == "/pages/tabBarPages/tradeIndex" ||
        to.path == config.Pc_Url
      ) {
        next();
      } else {
        next("/pages/login/loginIndex");
      }
    }
  
});

export default router;
