<template>
  <div style="min-height: 100vh; flex: 1">
    <topNavBar :leftIcon="true" />
    <div class="px-2">
      <div class="bg-general v-rounded-10 v-mt-8 v-py-14 base-shadow">
        <div v-if="type === '1'">
          <el-form
            label-position="left"
            label-width="auto"
            :model="addBankForm"
            style="height: fit-content"
          >
            <el-form-item :label="$t('bind-bank-name')" class="customLine">
              <el-input
                v-model="addBankForm.bankName"
                :placeholder="$t('bind-bank-name')"
              />
            </el-form-item>

            <el-form-item :label="$t('bind-bank-address')" class="customLine">
              <el-input
                v-model="addBankForm.bankAddress"
                :placeholder="$t('bind-bank-address')"
              />
            </el-form-item>
            <!-- <el-form-item :label="$t('bind-bank-swift')" class="customLine">
              <el-input
                v-model="addBankForm.swift"
                :placeholder="$t('bind-bank-swift')"
              />
            </el-form-item> -->
            <el-form-item :label="$t('bind-bank-payee')" class="customLine">
              <el-input
                v-model="addBankForm.payee"
                :placeholder="$t('bind-bank-payee')"
              />
            </el-form-item>
            <el-form-item
              :label="$t('bind-bank-payee-account')"
              class="customLine"
            >
              <el-input
                v-model="addBankForm.payeeAccount"
                :placeholder="$t('bind-bank-payee-account')"
              />
            </el-form-item>

            <el-form-item>
              <div class="flex justify-center v-w-full v-my-10">
                <el-button
                  size="large"
                  round
                  class="btn-blue v-w-full v-mx-10 v-border-0"
                  style="height: 10.66667vw"
                  @click="addBankCard"
                  >{{ $t("public-btn-add") }}</el-button
                >
              </div>
            </el-form-item>
          </el-form>
        </div>

        <div v-else-if="type === '2'">
          <van-form @submit="addCurrency">
            <van-cell-group inset class="bg-general">
              <van-field
                readonly
                clickable
                :label="$t('bind-currency')"
                v-model="currencyValue"
                :placeholder="currencyValue"
                class="bg-general custom-field"
                right-icon="arrow"
                @click="showCurrencyPicker = true"
              />
              <!-- Currency Picker -->
              <van-popup
                v-model:show="showCurrencyPicker"
                position="bottom"
                round
              >
                <van-picker
                  show-toolbar
                  :columns="currencyOptions"
                  :cancel-button-text="$t('public-text-cancel')"
                  :confirm-button-text="$t('public-text-confirm')"
                  @cancel="showCurrencyPicker = false"
                  @confirm="onConfirm"
                />
              </van-popup>

              <van-field
                v-model="walletAddress"
                class="bg-general"
                :label="$t('bind-wallet-address')"
                :placeholder="$t('bind-wallet-address')"
              />
            </van-cell-group>
            <div style="margin: 16px">
              <van-button
                round
                block
                class="btn-blue v-border-0"
                native-type="submit"
              >
                {{ $t("public-btn-add") }}
              </van-button>
            </div>
          </van-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, computed, onMounted } from "vue";
import topNavBar from "@/pages/components/common/topNavBar.vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { store } from "@/assets/store/data.js";
import { showToast } from "/node_modules/vant";
import Server from "@/Axios/index.ts";

const { t } = useI18n();
const router = useRouter();

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
});

const addBankForm = reactive({
  bankName: "",
  bankAddress: "",
  swift: "",
  payee: "",
  payeeAccount: "",
});

onMounted(async() => {
    const arr = await Server.post("/api/wallet/getWithdrawSymbolList");
    arr.forEach(element => {
        // console.log(element)
        currencyOptions.value.push({text:element.symbol,value:element.symbol})
    });
    
    currencyValue.value = currencyOptions.value[0]?.text || "";
})

// const bankCurrencyList = ref(store.value.bankCurrencyList);
const currencyOptions = ref([]);
const walletAddress = ref("");
const currencyValue = ref();
const showCurrencyPicker = ref(false);

const onConfirm = (val) => {
  currencyValue.value = val.selectedOptions[0].text;
  showCurrencyPicker.value = false;
};

const showMessage = (icon, message) => {
  showToast({
    type: icon.toLowerCase(),
    message,
    duration: 1000,
  });
};

const addBankCard = async () => {
    try {
        if (!addBankForm.bankName || !addBankForm.bankAddress || !addBankForm.payee || !addBankForm.payeeAccount){
            showMessage("fail", t("systemHint-plsFillIn"));
            return
        }
      await Server.post("/api/wallet/addWallet", {
        type: "bank",
        bank_name: addBankForm.bankName,
        bank_address: addBankForm.bankAddress,
        // swift: addBankForm.swift,
        payee_name: addBankForm.payee,
        payee_account: addBankForm.payeeAccount,
      });

      // bankCurrencyList.value.push({
      //   currency: currencyValue.value,
      //   walletAddress: walletAddress.value,
      // });
      showMessage("success", t("systemHint-requestSuccessful"));
      resetForm();
      // router.push({ name: "BindCurrency" });
      router.go(-1);
    } catch (error) {
      console.error(error);
    }
  
};

const addCurrency = async () => {
  if (!currencyValue.value || !walletAddress.value) {
    showMessage("fail", t("systemHint-plsFillIn"));
  } else {
    try {
      await Server.post("/api/wallet/addWallet", {
        type: "coin",
        blockchain_name: currencyValue.value,
        blockchain_address: walletAddress.value,
      });

      // bankCurrencyList.value.push({
      //   currency: currencyValue.value,
      //   walletAddress: walletAddress.value,
      // });
      showMessage("success", t("systemHint-requestSuccessful"));
      resetForm();
      // router.push({ name: "BindCurrency" });
      router.go(-1);
    } catch (error) {
      console.error(error);
    }
  }
};

const resetForm = () => {
  currencyValue.value = "";
  walletAddress.value = "";
};
</script>

<style scoped>
:deep(.el-input__wrapper) {
  box-shadow: 0 0 0 0;
  /* color:#0166fc !important; */
}
:deep(.el-form-item) {
  margin-bottom: 0;
}
:deep(.el-form-item__error) {
  padding-left: 10px;
}
:deep(.el-input__wrapper.is-focus),
:deep(.el-form-item.is-error),
:deep(.el-form-item.is-error .el-input__wrapper) {
  box-shadow: 0 0 0 0;
}
</style>
