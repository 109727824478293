<template>
  <div
    id="trade"
    style="min-height: 100vh; flex: 1"
    v-loading="$store.getters.GetLoding"
  >
    <div class="bg-main" style="padding: 4.26vw 5.3vw 21.3vw 5.3vw">
      <div class="TraTop bg-general base-shadow">
        <div class="flex items-center">
          <div
            class="k-text"
            style="margin: 0 0.5vw 0 2.6vw; font-weight: 700; font-size: 4vw"
            @click="openLeftMenu"
          >
            {{ symbolData.symbol }}
            <van-icon
              name="play"
              style="
                transform: rotate(90deg);
                text-align: center;
                font-size: 3vw;
                margin-left: 0.7vw;
              "
              size="12"
            />
          </div>
        </div>
        <div
          :class="
            Number(symbolData.price_change) >= 0
              ? 'text-kline-up'
              : 'text-kline-down'
          "
        >
          <span style="font-weight: 700; font-size: 4.8vw">
            {{
              symbolData.close ? Number(symbolData.close).toFixed(4) : "0.00"
            }}
          </span>
          <span
            v-if="symbolData.is_close == 0"
            style="margin: 0 5.3vw; font-size: 3.7333vw"
          >
            {{
              symbolData.increase > 0
                ? "+"
                : symbolData.increase == 0
                ? ""
                : "-"
            }}{{ symbolData.increase + "%" }}
          </span>
          <span v-else class="v-text-14 v-mx-20 text-color-5">{{
            $t("public-text-close")
          }}</span>
        </div>
      </div>

      <div class="TraBtn">
        <div
          class="BtnTime"
          :class="TimeData == '1M' ? 'active' : ''"
          @click="CutTimeData('1M')"
        >
          1M
        </div>
        <div
          class="BtnTime"
          :class="TimeData == '5M' ? 'active' : ''"
          @click="CutTimeData('5M')"
        >
          5M
        </div>
        <div
          class="BtnTime"
          :class="TimeData == '15M' ? 'active' : ''"
          @click="CutTimeData('15M')"
        >
          15M
        </div>
        <div
          class="BtnTime"
          :class="TimeData == '30M' ? 'active' : ''"
          @click="CutTimeData('30M')"
        >
          30M
        </div>
        <div
          class="BtnTime"
          :class="TimeData == '1H' ? 'active' : ''"
          @click="CutTimeData('1H')"
        >
          1H
        </div>
        <div
          class="BtnTime"
          :class="TimeData == '1D' ? 'active' : ''"
          @click="CutTimeData('1D')"
        >
          1D
        </div>
      </div>

      <div class="flex justify-between v-py-10">
        <div style="width: 100%; height: 250px">
          <div
            id="KLine"
            style="width: 100%; height: 100%"
            v-loading="KLoding"
          ></div>
        </div>
      </div>

      <div class="button-container v-mt-22">
        <div class="btnTab flex v-rounded-base text-center overflow-hidden">
          <!-- 市价 -->
          <div
            class="flex-1 v-py-6"
            :class="seletedTabBtn == 0 ? 'actived' : ''"
            @click="selectTab(0)"
          >
            {{ $t("trade-market_price") }}
          </div>
          <!-- 挂单  -->
          <div
            class="flex-1 v-py-6"
            :class="seletedTabBtn == 1 ? 'actived' : ''"
            @click="selectTab(1)"
          >
            {{ $t("order-pending_orders") }}
          </div>
          <!-- 秒合約 -->
          <div
            class="flex-1 v-py-6"
            :class="seletedTabBtn == 2 ? 'actived' : ''"
            @click="selectTab(2)"
            v-if="symbolData.mic_open_status == 1"
          >
            {{ $t("trade-quick_trade") }}
          </div>
        </div>
        <div v-if="seletedTabBtn == 0">
          <TradeOrder
            :curTab="0"
            :symbolData="symbolData"
            :symbolName="symbolData.symbol"
            :key="componentKey"
          />
        </div>
        <div v-if="seletedTabBtn == 1">
          <TradeOrder
            :curTab="1"
            :symbolData="symbolData"
            :symbolName="symbolData.symbol"
            :key="componentKey"
          />
        </div>
        <div v-if="seletedTabBtn == 2">
          <TradeOrder
            :curTab="2"
            :symbolData="symbolData"
            :symbolName="symbolData.symbol"
            :key="componentKey"
          />
        </div>
      </div>
    </div>

    <!-- 左侧菜单 -->
    <van-popup
      v-model:show="showTradeSideMenu"
      position="left"
      :style="{ width: '64%', height: '100%' }"
    >
      <van-list
        v-model:loading="loading"
        :finished="finished"
        :finished-text="$t('Loading')"
        @load="onLoad"
        :loading-text="$t('加载中...')"
        id="list"
        style="min-height: 70vh"
      >
        <van-cell
          class="flex justify-between v-py-20 v-px-10 v-border-0 customBottomLine v-pb-16"
          v-for="(item, idx) in lineData"
          :key="idx"
          @click="enterSelectedTrade(item)"
        >
          <div style="display: flex; justify-content: space-between">
            <div class="k-text">{{ item.symbol }}</div>
            <div :style="{ color: item.price_change >= 0 ? '#0199fd' : 'red' }">
              {{ item.open }}
            </div>
          </div>
        </van-cell>
      </van-list>
    </van-popup>

    <tabBar />
  </div>
</template>

<script setup>
import {
  onMounted,
  ref,
  watch,
  getCurrentInstance,
  nextTick,
  computed,
  onUnmounted,
} from "vue";
import tabBar from "@/pages/components/common/tab-bar.vue";
import { useRouter, useRoute } from "vue-router";
import { getKData, ViveKLine, updateData } from "@/api/chart";
import TradeOrder from "@/pages/tabBarPages/tradeOrder.vue";
import axios from "axios";
import { useStore } from "vuex";

const loading = ref(false);
const finished = ref(false);
const store = useStore();
const { appContext } = getCurrentInstance();
const route = useRoute();
const router = useRouter();
// 全部产品数据
const lineData = ref([]);
// 单个产品数据
const symbolData = ref({});
// k线数据
const PageData = ref();
// 时间
const TimeData = ref("15M");
const selectedTrade = ref(null);
const seletedTabBtn = ref(0);
const componentKey = ref(0);
const showTradeSideMenu = ref(false);
const KLoding = ref(false);
let PageIndex = 1;
let cancelTokenSource = axios.CancelToken.source();
let klineDom;

const InterId = ref(null);

onMounted(async () => {
  InterId.value = setInterval(async () => {
    try {
      PageData.value = await getKData(
        TimeData.value,
        symbolData.value.symbol,
        cancelTokenSource
      );
      klineDom = ViveKLine("KLine", PageData.value, klineDom);
    } catch (e) {
      console.error("定时器中的数据获取失败", e);
    }
  }, 30000);
  // store.commit('SetLoading', true)
  await GetArrData();
  symbolData.value = store.getters.GetSymbolData.id
    ? store.getters.GetSymbolData
    : lineData.value[0];
  await GetPageData();
  setupWebSocket();
});

// watch(() => selectedTrade.value, (newVal) => {
//     PageData.value = newVal
//     applyNewData('KLine')
// },
//     { deep: true })

const CutTimeData = async (open) => {
  KLoding.value = true;
  TimeData.value = open;
  await GetPageData();
  KLoding.value = false;
};

async function GetPageData() {
  PageData.value = await getKData(
    TimeData.value,
    symbolData.value.symbol,
    cancelTokenSource
  );
  nextTick(() => {
    klineDom = ViveKLine("KLine", PageData.value, klineDom);
  });
}

const selectTab = (index) => {
  seletedTabBtn.value = index;
};

const openLeftMenu = () => {
  showTradeSideMenu.value = true;
};

const enterSelectedTrade = async (item) => {
  showTradeSideMenu.value = false;
  clearInterval(InterId.value);
  TimeData.value = "15M";
  symbolData.value = item;
  KLoding.value = true;
  await GetPageData();
  KLoding.value = false;
};

// 获取左侧数据
async function GetArrData() {
  const arr = await store.dispatch("GetAreaData", {
    data: {
      page: PageIndex,
      per_page: 6,
      cancelTokenSource: cancelTokenSource.token,
    },
  });
  if (arr) {
    const data = arr.data;
    lineData.value.push(...data);
    // 数据全部加载完成
    if (arr.length < 15) {
      finished.value = true;
    }
  } else {
    finished.value = true;
    loading.value = false;
  }
}

// 下滑
async function onLoad() {
  PageIndex++;
  await GetArrData();
  loading.value = false;
}

const socket = ref();

// 列表数据更新
function setupWebSocket() {
  socket.value = new WebSocket("wss://api.hzwh888.vip:2348"); // new WebSocket("ws://103.197.158.7:2348"); //new WebSocket('wss://api.jiekourapi.com:2348'); // 使用本地开发服务器的 WebSocket URL
  socket.value.onmessage = (event) => {
    const data = JSON.parse(event.data);
    uplineData(data);
  };

  socket.value.onopen = () => {
    console.log("WebSocket 连接已建立");
  };
}

function uplineData(newData) {
  // 将 newData 转换为查找对象（哈希表），以 symbol 为键
  const newDataMap = newData.reduce((acc, dataItem) => {
    acc[dataItem.symbol] = dataItem;
    return acc;
  }, {});

  // 更新 lineData 数组
  lineData.value = lineData.value.map((item) => {
    // 查找对应的更新数据
    const updatedItem = newDataMap[item.symbol];
    // 如果存在更新的数据，合并原数据和新数据；否则，保持原数据不变
    return updatedItem ? { ...item, ...updatedItem } : item;
  });

  // 更新 symbolData 对象
  try {
    symbolData.value = {
      ...symbolData.value,
      ...newDataMap[symbolData.value.symbol],
    };
    // 更新k线数据
    const klinedata = {
      symbol: newDataMap[symbolData.value.symbol].symbol,
      open: newDataMap[symbolData.value.symbol].open,
      close: newDataMap[symbolData.value.symbol].close,
      high: newDataMap[symbolData.value.symbol].high,
      low: newDataMap[symbolData.value.symbol].low,
      amount: newDataMap[symbolData.value.symbol].amount,
      volume: newDataMap[symbolData.value.symbol].vol,
      timestamp: PageData.value[PageData.value.length - 1].timestamp,
    };
    updateData(klineDom, klinedata);
  } catch (e) {
    console.log(e);
  }
}

onUnmounted(() => {
  try {
    console.log("组件卸载时的 InterId:", InterId.value);
    clearInterval(InterId.value);
    InterId.value = null;

    cancelTokenSource.cancel("页面卸载，取消请求");

    if (socket.value) {
      socket.value.close();
    }
  } catch (e) {
    console.log(e);
  }
});
</script>

<style scoped>
.TraTop {
  font-size: 3.2vw;
  border-radius: 2.6vw;
  display: flex;
  justify-content: space-between;
  height: 6.777vw;
  align-items: center;
}

.TraBtn {
  display: flex;
  justify-content: space-around;
  padding: 2.444vw 0;
}

.BtnTime {
  /* background: #eeeeee; */
  padding: 0.053333vw 0;
  width: 12.26667vw;
  border-radius: 3.2vw;
  font-size: 3.2vw;
  text-align: center;
}

/* .BtnTime.active{
    background: #0166fc !important;
    color: white !important;
} */
</style>
