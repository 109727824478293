<template>
  <div
    class="v-mt-10 v-mx-10 base-shadow v-text-12 coin-list bg-general v-rounded-8"
  >
    <van-swipe-cell :style="showDel ? showBtnStyle : hideBtnStyle">
      <div class="bg-general v-p-20 v-rounded-8">
        <div>
          <span class="pr-3 k-text">{{ $t("bind-bank-name") }}:</span>
          <span class="k-text">{{ items.blockchain_name }}</span>
        </div>
        <div class="v-mt-6">
          <span class="pr-3 k-text">{{ $t("bind-bank-address") }}:</span>
          <span class="k-text">{{ items.blockchain_address }}</span>
        </div>
      </div>
      <template #right>
        <div
          class="flex items-center v-h-full bg-kline-down v-rounded-right"
          @click="comfirmDelete(items.id)"
        >
          <van-button class="bg-kline-down v-border-0">
            <span class="text-white v-text-12">{{
              $t("public-btn-delete")
            }}</span>
          </van-button>
        </div>
      </template>
    </van-swipe-cell>
  </div>
</template>
<script setup>
import { ref, defineEmits } from "vue";
import { useI18n } from "vue-i18n";
import { ElMessageBox } from "element-plus";

const { t } = useI18n();

const props = defineProps({
  items: {
    type: Object,
    default: () => {},
  },
});
const showDel = ref(false);
const showBtnStyle = ref(
  "transform: translate3d(-75.2031px, 0px, 0px); transition-duration: 0.6s;"
);
const hideBtnStyle = ref(
  "transform: translate3d(0px, 0px, 0px); transition-duration: 0.6s;"
);

const showDialog = ref(false);
const popDelete = () => {
  showDialog.value = true;
};

const emit = defineEmits(["delete-wallet"]);

const comfirmDelete = (id) => {
  ElMessageBox.confirm(t("popupHint-confirm_to_delete"), {
    confirmButtonText: t("public-text-confirm"),
    cancelButtonText: t("public-text-cancel"),
  })
    .then(() => {
      emit("delete-wallet", id);
    })
    .catch(() => {});
};
</script>

<style scoped>
:deep(.van-button--default) {
  /* border: 0px #f23c48 solid; */
}

:deep(.van-swipe-cell__right) {
  transform: translate3d(101%, 0, 0);
}
</style>
