<template>
  <div style="min-height: 100vh; flex: 1">
    <topNavBar :leftIcon="true" :headerTitle="$t('mine-other')" />
    <div class="px-2" id="mineList">
      <!-- 退出登陆 -->
      <div
        class="item-bg rounded-default flex justify-between items-center v-py-8 v-px-12 v-mt-10 base-shadow"
        @click="showLogoutDialog"
      >
        <div class="flex items-center">
          <div
            class="v-p-6 icon-bg v-rounded-10 flex flex-col justify-center items-center"
          >
            <van-icon name="share" style="font-size: 1.4rem" />
          </div>
          <span class="v-p-10 k-text font-semibold v-text-14">{{
            $t("logout-title")
          }}</span>
        </div>
        <el-icon style="font-size: 1.5rem">
          <ArrowRight />
        </el-icon>
      </div>

      <!-- 主题颜色更换 -->
      <div
        class="item-bg rounded-default flex justify-between items-center v-py-8 v-px-12 v-mt-10 base-shadow"
      >
        <div class="flex items-center">
          <div
            class="v-px-6 icon-bg v-rounded-10 flex flex-col justify-center items-center"
          >
            <i class="bi bi-moon-stars-fill" style="font-size: 1.4rem"></i>
          </div>
          <span class="v-p-10 k-text font-semibold v-text-14">{{
            $t("public-night_mode")
          }}</span>
        </div>
        <van-switch v-model="themeIsDark" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import topNavBar from "@/pages/components/common/topNavBar.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { showConfirmDialog, showToast } from "/node_modules/vant";

const props = defineProps({
  themeIsDark: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const store = useStore();
const router = useRouter();
const themeIsDark = ref(false); // 初始theme为'light'

const showLogoutDialog = async () => {
  showConfirmDialog({
    title: t("dialog-title"),
    message: t("dialog-logout-conetnt"),
    confirmButtonText: t("public-text-confirm"),
    cancelButtonText: t("public-text-cancel"),
  })
    .then(async() => {
      await store.dispatch("LogOut").then(() => {
        setTimeout(() => {
          router.push("/");
          showToast({
            message: t("systemHint-successful_exit"),
            icon: "success",
            duration: 600,
          });
        }, 1000);
      });
    })
    .catch(() => {
      // Handle the cancel action
    });
};

onMounted(() => {
  const savedTheme = localStorage.getItem("theme");
  themeIsDark.value = savedTheme === "dark";
});

watch(themeIsDark, (val) => {
  const theme = val ? "dark" : "light";
  document.documentElement.setAttribute("data-theme", theme);
  localStorage.setItem("theme", theme);
  store.dispatch("setTheme", theme);
});
</script>

<style>
.el-dialog {
  padding: 0;
}
</style>

<style scoped>
.button {
  position: relative;
  padding: 0 !important;
}

.button-box {
  display: flex;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.cancelBtn {
  flex: 1;
  height: 12.8vw;
  bottom: 0;
  margin: 0;
  font-size: 18px;
  border-radius: 0 0 0 15px;
}

.comfirmBtn {
  flex: 1;
  height: 12.8vw;
  bottom: 0;
  margin: 0;
  font-size: 18px;
  color: #0166fc;
  border-radius: 0 0 15px 0;
}

.el-button + .el-button {
  margin-left: 0 !important;
}
</style>
