<template>
  <div style="min-height: 100vh; flex: 1">
    <topNavBar :leftIcon="true" :headerTitle="$t('mine-verifyName')" />
    <div class="auth-page p-3 mt-3">
      <div class="v-text-12 k-text pl-4 mb-5">
        {{ $t("verify-text-upload") }}
      </div>
      <div class="bg-general v-rounded-10 v-p-10 base-shadow">
        <!-- Front -->
        <div
          class="flex items-center justify-between verify-bg v-rounded-4 v-p-10"
        >
          <div class="pl-2">
            <div class="v-text-14 k-text mb-6">
              {{ $t("verify-front") }}
            </div>
            <div class="v-text-12 text-color-7">
              {{ $t("verify-front-desc") }}
            </div>
          </div>
          <div class="van-uploader">
            <div class="van-uploader__wrapper">
              <div
                class="van-uploader__input-wrapper w-[49.6vw] h-[32.53333vw]"
              >
                <img
                  :src="
                    frontImageUrl
                      ? frontImageUrl
                      : require('@/assets/image/verify-img.png')
                  "
                  class="w-[49.6vw] h-[32.53333vw]"
                />
                <input
                  type="file"
                  class="van-uploader__input"
                  accept="image/*"
                  @change="previewFrontImage"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Back -->
        <div
          class="flex items-center justify-between verify-bg v-rounded-4 p-3 mt-3"
        >
          <div class="pl-2">
            <div class="v-text-14 k-text mb-6">
              {{ $t("verify-back") }}
            </div>
            <div class="v-text-12 text-color-7">
              {{ $t("verify-back-desc") }}
            </div>
          </div>
          <div class="van-uploader">
            <div class="van-uploader__wrapper">
              <div
                class="van-uploader__input-wrapper w-[49.6vw] h-[32.53333vw]"
              >
                <img
                  :src="
                    backImageUrl
                      ? backImageUrl
                      : require('@/assets/image/verify-img.png')
                  "
                  class="w-[49.6vw] h-[32.53333vw]"
                />
                <input
                  type="file"
                  class="van-uploader__input"
                  accept="image/*"
                  @change="previewBackImage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-general v-rounded-10 v-mt-10 base-shadow">
        <div class="p-3 v-text-12 k-text">{{ $t("verify-text-info") }}</div>
        <van-form @submit="submitVerify">
          <van-cell-group inset class="bg-general">
            <van-field
              v-model="name"
              class="bg-general"
              style="font-size: 3.2vw; line-height: 5vw"
              :label="$t('verify-name')"
              :placeholder="$t('verify-enterName')"
              :rules="[{ required: true, message: $t('verify-enterName') }]"
            />
            <van-field
              v-model="passportId"
              class="bg-general"
              style="font-size: 3.2vw; line-height: 5vw"
              :label="$t('verify-passportId')"
              :placeholder="$t('verify-enterPassportId')"
              :rules="[
                { required: true, message: $t('verify-enterPassportId') },
              ]"
            />
          </van-cell-group>
        </van-form>
      </div>
      <div class="flex justify-center text-center">
        <div class="w-full v-h-40 v-mx-8 v-my-20">
          <van-button
            round
            block
            native-type="submit"
            class="btn-blue v-h-40 v-text-14 v-border-0"
            @click="submitVerify"
          >
            {{ $t("public-text-confirm") }}
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { showToast } from "/node_modules/vant";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import topNavBar from "@/pages/components/common/topNavBar.vue";
import Server from "@/Axios/index.ts";

const { t } = useI18n();
const router = useRouter();
const store = useStore();
const frontImageUrl = ref(null);
const frontImageBase64 = ref(null);
const backImageUrl = ref(null);
const backImageBase64 = ref(null);
const name = ref("");
const passportId = ref("");

const showMessage = (icon, message) => {
  showToast({
    type: icon.toLowerCase(),
    message,
    duration: 1000,
  });
};

//To-DO: verify的逻辑需包括图片的上传
const submitVerify = async () => {
  if (!name.value || !passportId.value) {
    showMessage("fail", t("systemHint-plsSubmit"));
  } else {
    try {

        const response = await Server.post("/api/user/auth", {
        name: name.value,
        idnumber: passportId.value,
        front: frontImageBase64.value,
        back: backImageBase64.value,
      });

      showMessage("success", t("systemHint-changeSuccess"));
      // 保存提交时间戳
      localStorage.setItem("verifySubmitTime", Date.now());
      // 跳转到 mine/auth 页面
      router.replace({ path: "/pages/components/mine/verifyName/verifyAuth" });
    } catch (error) {
      console.error(error);
    }
  }
};

const previewFrontImage = async (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      frontImageUrl.value = e.target.result;
    };
    reader.readAsDataURL(file);

    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await Server.post("/api/common/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      frontImageBase64.value = response.list.slice(1);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  }
};

//上传图片
const previewBackImage = async (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      backImageUrl.value = e.target.result;
    };
    reader.readAsDataURL(file);

    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await Server.post("/api/common/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      backImageBase64.value = response.list.slice(1);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  }
};

onMounted(() => {
    const UserData = store.getters.GetInfoData;
    console.log(UserData)

    if (UserData.is_submit_auth == 1){
        router.replace({ path: "/pages/components/mine/verifyName/verifyAuth" });
    }

//   if (submitTime) {
//     const currentTime = Date.now();
//     const timeDifference = currentTime - parseInt(submitTime, 10);
//     const hoursDifference = timeDifference / (1000 * 60 * 60);

//     if (hoursDifference < 1) {
//       //24
//       router.replace({ path: "/pages/components/mine/verifyName/verifyAuth" });
//     } else {
//       localStorage.removeItem("verifySubmitTime");
//     }
//   }
});
</script>

<style scoped>
.van-cell-group--inset {
  margin: 1vw !important;
}
.van-uploader {
  position: relative;
  display: inline-block;
}

.van-uploader__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.van-uploader__input-wrapper {
  position: relative;
}

.van-uploader__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
}

:deep(.el-input__wrapper) {
  box-shadow: 0 0 0 0;
}
:deep(.el-form-item__error) {
  padding-left: 10px;
}
:deep(.el-input__wrapper.is-focus),
:deep(.el-form-item.is-error),
:deep(.el-form-item.is-error .el-input__wrapper) {
  box-shadow: 0 0 0 0;
}
</style>
